<template>
  <div class="_pc-pad-top-35 _mb-pad-top-35"
    style="background-color:#fff;border-top: 1px solid rgba(238, 238, 238, .2);">

    <div class=" _pc-pad-x-260 _mb-pad-x-25">
      <div class="_flex-row-bet _mb-display-block _pc-pad-bottom-25 _mb-pad-bottom-5">
        <div class="_black _pc-font-16 _mb-font-22 _flex-col-bet">
          <img class="_pc-w-165 _mb-w-230" v-if="index_logo != ''" :src="index_logo.image_input[0]" alt="">
          <div class="_pc-w-45 _mb-w-max _pc-mar-y-20 _mb-display-none _mb-mar-top-30 _mb-mar-bottom-25"
            style="border-bottom:1px solid rgba(153, 153, 153, .6);"></div>
          <!-- <div class="_pc-w-45 _mb-w-max _pc-mar-y-20 _mb-display-block _mb-mar-top-30 _mb-mar-bottom-25"></div> -->
          <div class="_mb-flex-row-bet-mid">
            <div class="_mb-pad-top-15 _pc-mar-bottom-10 _flex-1">
              <!-- <div class="_flex-row-mid _pc-font-17 _mb-font-28 _mb-mar-bottom-30 _pc-mar-bottom-20"> <img
                  class="_pc-w-20 _mb-w-30 _pc-mar-right-10 _mb-mar-right-10"
                  src="@/assets/image/footer-location-icon.png" alt="">
                <div v-html="office_address.content"></div>
              </div> -->
              <div class="_flex-row-mid _mb-display-none">
                <div class="_flex-row-mid _pc-font-17 _mb-font-28 _mb-mar-bottom-30 _pc-mar-bottom-20"> <img
                    class="_pc-w-20 _mb-w-30 _pc-mar-right-10 _mb-mar-right-10"
                    src="@/assets/image/footer-wechat-ico.png" alt="">
                  <div v-html="wechat.content" style="font-family:'Microsoft YaHei';"></div>
                </div>
              </div>
              <div class="_flex-row-mid _mb-display-none">
                <div class="_flex-row-mid _pc-font-17 _mb-font-28 _mb-mar-bottom-30 _pc-mar-bottom-20"> <img
                    class="_pc-w-20 _mb-w-30 _pc-mar-right-10 _mb-mar-right-10"
                    src="@/assets/image/footer-email-ico.png" alt="">
                  <div v-html="phone.content" style="font-family:'Microsoft YaHei';"></div>
                </div>
              </div>
              <div class="_flex-row-mid _pc-font-17 _mb-font-28 _mb-mar-bottom-30 _pc-mar-bottom-20 _pc-display-none">
                <img class="_pc-w-20 _mb-w-30 _pc-mar-right-10 _mb-mar-right-10"
                  src="@/assets/image/footer-email-ico.png" alt="">
                <div v-html="phone.content" style="font-family:'Microsoft YaHei';"></div>
              </div>
              <div class="_flex-row-mid _pc-font-17 _mb-font-28 _mb-mar-bottom-30 _pc-mar-bottom-20 _pc-display-none">
                <img class="_pc-w-20 _mb-w-30 _pc-mar-right-10 _mb-mar-right-10"
                  src="@/assets/image/footer-wechat-ico.png" alt="">
                <div v-html="wechat.content" style="font-family:'Microsoft YaHei';"></div>
              </div>
              <!-- <div class="_flex-row-mid _pc-font-17 _mb-font-28"> <img
                  class="_pc-w-20 _mb-w-30 _pc-mar-right-10 _mb-mar-right-10" src="@/assets/image/footer-time-icon.png"
                  alt="">
                <div v-html="workTime.content"></div>
              </div> -->
            </div>
            <router-link class="" to="">
              <div @click="showPop"
                class="_btn-hover _black _flex-row-mid _pc-pad-x-30 _pc-pad-bottom-10 _pc-pad-top-10 _pc-mar-top-15 _mb-pad-x-40 _mb-pad-bottom-20 _mb-pad-top-20"
                style="border-radius:25px;background-color:#D92621;color: #fff;width: fit-content;">反馈与建议
                <img class="_pc-w-10 _pc-mar-left-10 _mb-w-20 _mb-mar-left-15" src="@/assets/image/right-arrow.png"
                  alt="" style="color:#102F45; ;">
              </div>
            </router-link>
          </div>
        </div>

        <div class="_flex-row _mb-display-none ">
          <div class="_flex-col-mid _footer-nav">
            <div class="_pc-font-18 _pc-mar-bottom-5" style="color:#D92621;">{{ siteName }}</div>
            <div class="_flex-col-mid _w-max">
              <router-link class="_pc-mar-top-25 _mb-mar-top-5 _pc-font-16 _w-max _btn-hover" v-show="item.status"
                v-for="(item, index) in nav_list" :key="index" :to='item.link'>
                <div class=" _black" style="font-family:'Microsoft YaHei';">{{ item.title }}</div>
              </router-link>
            </div>
          </div>

          <div class="_flex-col-mid _footer-nav">
            <div class="_pc-font-18 _pc-mar-bottom-5 _w-max" style="color:#D92621;">常见问题</div>
            <div class="_flex-col-mid _w-max">
              <router-link :to="{ path: '/question/detail', query: { id: item.id, f_id: item.f_id } }"
                class="_pc-mar-top-25  _mb-mar-top-5 _pc-font-16 _w-max _btn-hover" style="cursor:pointer;"
                v-for="(item, index) in quest_list" :key="index" v-show="item.is_hot">
                <div class=" _black" style="font-family:'Microsoft YaHei';" @click="to_quest(item.id, item.f_id)">
                  {{ item.title }}</div>
              </router-link>
            </div>
          </div>

          <!-- <div class="_flex-col-mid ">
            <div class="_pc-font-18 _pc-mar-bottom-5 _w-max" style="color:#D92621;">售后服务</div>
            <div class="_flex-col-mid _w-max">
              <router-link :to="{ path: '/question/detail', query: { id: item.id, f_id: item.f_id } }"
                class="_pc-mar-top-25  _mb-mar-top-5 _pc-font-16 _w-max _btn-hover" style="cursor:pointer;"
                v-for="(item, index) in service_list" :key="index" v-show="item.is_hot">
                <div class="_pc-w-130 _line-1 _black" style="font-family:'Microsoft YaHei';"
                  @click="to_quest(item.id, item.f_id)">
                  {{ item.title }}</div>
              </router-link>
            </div>
          </div> -->


          <!-- <div class="_flex-col-mid _footer-nav">
            <div class="_pc-font-18 _pc-mar-bottom-5 _w-max" style="color:#fff;">售后服务</div>
            <div class="_flex-col-mid _w-max">
              <a class="_pc-mar-top-25 _mb-mar-top-5 _pc-font-16 _w-max _btn-hover" target="_blank"
                v-for="(item, index) in friend_nav" :key="index" :href='item.link'>
                <div class=" _white" style="opacity: 0.5;">{{ item.title }}</div>
              </a>
            </div>
          </div> -->
        </div>

        <div
          class="_flex-row _black _pc-font-18 _mb-font-28 _mb-pad-x-60 _mb-flex-row-mid _mb-mar-bottom-30 _mb-mar-top-30">

          <div class="_flex-col-mid _mb-flex-1 _pc-mar-right-55">
            <img class="_pc-w-160 _mb-w-max _pc-mar-bottom-30 _mb-mar-bottom-30 " :src="account.image_input" alt="" />
            官方公众号
          </div>

          <div class="_mb-h-190 _pc-display-none _white-bg _mb-mar-x-70" style="width:1px;opacity:0.3"></div>

          <div class="_flex-col-mid  _mb-mar-right-0 _mb-flex-1">
            <img class="_pc-w-160 _mb-w-max _pc-mar-bottom-30 _mb-mar-bottom-30" :src="routine.image_input" alt="" />
            官方小程序
          </div>

        </div>

      </div>
    </div>
    <div class=" _pc-pad-x-260 _mb-pad-x-25" style="background-color: #D92621;">
      <div
        class="_pc-font-14 _mb-font-22 _pc-pad-top-15 _pc-pad-bottom-15 _mb-pad-top-25 _mb-pad-bottom-25 _flex-row-center-mid _mb-display-block"
        style="border-top:1px solid rgba(255,255,255,0.2);color:#FFFFFF;">
        <div class="_flex-row-center-mid _mb-mar-bottom-5">
          <div class="_pc-mar-right-25 _mb-mar-right-35">{{ address }}</div>
          <div class="_flex-row _pc-mar-right-25">
            <div class="_flex-row-mid"><img class="_pc-w-15 _pc-mar-right-10 _mb-w-20 _mb-mar-right-10"
                src="@/assets/image/footer-ico.png" alt=""></div>
            <div class="_flex-row-mid"><a target="_blank" style="color:#FFFFFF;"
                href="https://beian.miit.gov.cn/#/Integrated/index">{{ siteID }}</a></div>
          </div>
        </div>
        <!-- <div class="_mb_w-max _text-center"><a target="_blank" style="color:#FFFFFF;opacity: 0.5;"
            href="http://www.szuxx.com/">有小象技术支持</a></div> -->
      </div>
    </div>








    <!-- 弹窗 -->
    <div class="head-pop" v-if="is_show">
      <div
        class="_pc-pad-x-30 _mb-pad-x-30 _pc-pad-y-40 _mb-pad-y-50 _pos-rel _white-bg _pc-w-650 _mb-w-max _mb-pos-abs _mb-bottom _mb-left _pc-font-16 head-radius">
        <div class="_pos-rel _flex-row-center-mid">
          <div class="_pc-font-24 _b _mb-font-42">反馈与建议</div>
          <img class="_pc-w-30 _mb-w-40 _pos-abs-right" style=" cursor: pointer;" @click="open_show"
            src="@/assets/image/guanbi.png" alt="">
        </div>

        <div class="_flex-row-bet-mid _pc-mar-top-20 _mb-mar-top-30 _mb-flex-col _mb-font-28">
          <input type="text"
            class="_pc-w-230 _mb-w-max _mb-pad-x-30 _mb-pad-y-30 _pc-mar-right-20 _mb-mar-right-0 _pc-pad-x-20 _pc-pad-y-15 _mb-pad-y-5 _grey-f9-bg"
            placeholder="您的称呼" v-model="data.name">
          <input type="text"
            class="_flex-1 _mb-w-max _mb-pad-x-30 _mb-pad-y-30 _mb-mar-top-30 _pc-pad-x-20 _pc-pad-y-15 _mb-pad-y-5 _grey-f9-bg"
            placeholder="您的联系方式" v-model.number="data.phone" maxlength="11">
        </div>

        <textarea
          class="_pc-pad-x-20 _mb-pad-x-25 _pc-pad-y-15 _mb-font-28 _mb-pad-y-25 _w-max _pc-mar-top-20 _mb-mar-top-35 _grey-f9-bg"
          style="border-radius:12px;" cols="30" rows="5" placeholder="请在此留下您的宝贵意见..." v-model="data.content"></textarea>

        <div class="_w-max _flex-row-center-mid _pc-mar-top-15 _mb-mar-top-40">
          <router-link to="" class="_mb-w-max">
            <div
              class="_btn-hover _pc-pad-y-15 _mb-pad-y-20 _mb-font-28 _pc-pad-x-110 _white _flex-row-mid _mb-w-max _flex-row-center-mid"
              style="background:#D92621;" @click="save">
              <div>提交留言</div>
              <img class="_pc-w-20 _pc-mar-left-10 _mb-w-30 _mb-mar-left-10" src="@/assets/image/right-ico.png" alt="">
            </div>
          </router-link>
        </div>
      </div>

    </div>



  </div>
</template>

<script>
export default {
  name: 'JiayeFooter',

  data() {
    return {
      is_show: false,
      nav_list: [],
      data: {
        name: '',
        phone: '',
        content: '',
      },
      checked: true,
      quest_list: [],
      service_list: [],
      routine: {},
      account: {},
      phone: [],
      wechat: [],
      friend_nav: [],
      web_logo: '',//网站logo
      index_logo: '',
      workTime: {},
      office_address: {},
    };
  },
  props: {
    address: {
      type: String,
      default: '',
    },
    siteID: {
      type: String,
      default: '',
    },
    siteName: {
      type: String,
      default: '',
    },
  },

  beforeMount() {
    this.get_routine();
  },
  mounted() {
    this.get_nav();
    this.get_news();
    this.get_firend_nav();
    this.get_logo();
  },

  methods: {
    showPop() {
      this.is_show = true;
      let body = document.querySelector('body');
      body.style.height = 100 + 'vh';
      body.style.overflow = 'hidden';
    },

    open_show() {
      this.is_show = false;
      let body = document.querySelector('body');
      body.style.height = 100 + '%';
      body.style.overflow = 'auto';
    },




    async get_firend_nav() {  //获取友情导航栏
      await this.$axios.get('site/link_list', {}).then(res => {
        this.friend_nav = res.data.data.list
      })
    },


    async get_nav() {  //获取导航栏
      await this.$axios.get('site/nav_list/0', {}).then(res => {
        res.data.data.list.forEach(val => {
          if (val.link != '/question/detail') {
            this.nav_list.push(val)
          }
        })
      })
    },


    async get_logo() { //获取网站logo
      let that = this;
      await that.$axios.get('site/get_site_newList?cid=25&page=1&limit=9').then(res => {
        that.web_logo = res.data.data.all[1];
        that.index_logo = res.data.data.all[1];
      })
    },

    to_quest(id, f_id) {//前往常见问题
      // this.$router.push({path:'/question/detail',query:{id,f_id}})
    },
    async get_news() {  //获取常见问题列表
      let that = this;
      await that.$axios.get('get_article_tree?id=14', {}).then(res => {
        let list = [];

        res.data.data[0].child.forEach((val => {
          val.artcile.forEach(value => {
            value.f_id = val.id;
            list.push(value)
          })

        }))
        console.log(res.data.data[0].child, 'res.data.data[0].child');
        this.quest_list = list;
        console.log(this.quest_list, 'this.quest_list');
      })

      await that.$axios.get('get_article_tree?id=34', {}).then(res => {
        let list = [];
        res.data.data[0].artcile.forEach((val => {
          val.f_id = res.data.data[0].id;
          list.push(val)
        }))

        this.service_list = list;
      })
    },


    async get_routine() { //获取小程序/公众号
      let that = this;
      await that.$axios.get('site/get_site_newList?cid=7&page=1&limit=15').then(res => {
        that.routine = res.data.data.all[2];
        that.account = res.data.data.all[3];
        that.phone = res.data.data.all[4];
        that.wechat = res.data.data.all[5];
        that.workTime = res.data.data.all[0];
        that.office_address = res.data.data.all[1];
      })
    },


    async save() {
      let that = this;
      let data = that.data;
      if (data.name) { } else { alert('请填写名字'), that.checked = true; return }
      if (data.phone) { } else { alert('请填写手机号码'), that.checked = true; return }
      if (data.content) { } else { alert('请填写留言内容'), that.checked = true; return }

      await this.$axios.post('site/message_post', data).then(res => {
        alert(res.data.msg);
        that.checked = true;
      })

      that.open_show();
      that.data = {};
    },
  },
};
</script>

<style scoped>
._footer-nav {
  margin-right: 3.64583vw;
}

._footer-nav:last-child {
  margin-right: 0;
}

input {
  border: 0;
}

textarea {
  border: 0;
}

._btn-hover:hover {
  opacity: 1;
}

.head-radius {
  border-radius: 12px
}

@media screen and (max-width: 768px) {
  .head-radius {
    border-radius: 6.4vw 6.4vw 0px 0px
  }
}
</style>