<template>
  <!-- 导航栏 -->
  <div>
    <!-- pc头部 -->
    <!-- <div class="_com-head-box _com-head-height _mb-display-none" :style="scroll_top < 200 && nav == '/'
      ? 'color:white;'
      : 'background-color:#FFF;color:black;'
      "> -->
    <div class="_com-head-box _com-head-height _mb-display-none" :style="false
      ? 'color:white;'
      : 'background-color:#FFF;color:black;'
      ">
      <div class="_pc-pad-x-80 _flex-row-bet-mid _h-max" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
        <!-- logo -->
        <router-link class="_pc-w-165" to="/">
          <img :class="false ? '_display-none' : ''" style="width: 100%" :src="web_logo.image_input" alt="" />
          <img :class="false ? '' : '_display-none'" style="width: 100%" :src="index_logo.image_input" alt="" />
        </router-link>

        <div class="_pc-font-20 _flex-row-mid _h-max" style="width: 60%">
          <div class="_h-max _flex-1 _flex-row-ard-mid _pc-pad-right-60">
            <!-- 栏目 -->
            <router-link class="_pos-rel _h-max _flex-row-mid"
              :to="item.data ? item.link + '?data=' + item.data : item.link" v-for="(item, index) in nav_list"
              :key="index" v-show="item.status">
              <div class="_b" :class="nav == item.link
                ? false
                  ? ''
                  : 'nav_color'
                : 'nav-opac'
                ">
                {{ item.title }}
              </div>
              <div class="_pos-abs-bottom _w-max" :class="false
                ? 'nav-index-border-top'
                : 'nav-border-top '
                " v-if="nav == item.link"></div>
            </router-link>
          </div>

          <div class="nav-opac" style="border-right: 1px solid white; height: 35%"></div>

          <div class="_flex-row-mid _pc-mar-left-60">
            <!-- 微信 -->
            <!-- <div class="_pc-mar-right-20 _pos-rel _wechat-box" >
              <div class="_flex-row-mid _h-max">
                <img class="_pc-w-40" src="@/assets/image/wechat-ico.png"  style="cursor:pointer;" alt=""> 
              </div>
              <div class="_pc-w-210 _pos-abs" style="left:-450%; bottom:-540%"><img class="_w-max" :src="account.image_input" alt=""> </div> 
            </div> -->
            <div class="_pc-mar-right-20 _pos-rel _wechat-box _pc-w-40 _border-circle _flex-row-center-mid"
              style="height: 2.08333vw; background-color: #f6f6f6" @click="is_show = !is_show">
              <img class="_pc-w-20" src="@/assets/image/wechat-ico.png" style="cursor: pointer;" alt="" />
              <div class="_pc-w-210 _pos-abs" style="left: -350%; bottom: -550%">
                <img class="_w-max" :src="account.image_input[0]" alt="" />
              </div>
            </div>

            <div
              class="_flex-row-mid _pc-pad-x-15 _pc-pad-top-10 _pc-pad-bottom-10 _mb-pad-y-010 _btn-hover _white-bg theme-color"
              style="
                cursor: pointer;
                border-radius: 20px;
                background-color: #f6f6f6;
              " @click="show_pop" :class="this.$root._route.path == '/' ? '' : ''">
              <img class="_pc-w-15 _pc-mar-right-10" src="@/assets/image/uni-ico.png" alt="" />
              <div>我要寄货</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 手机头部 -->
    <div @click.stop class="_com-head-box _white-bg _flex-row-bet-mid _mb-pad-y-15 _mb-pad-x-30 _pc-display-none"
      id="phoneHead">
      <div class="_flex-row-mid">
        <!-- <div class="_mb-w-60 _border-circle _flex-row-center-mid _mb-mar-right-20"
          @click.stop="showPop_isShow = !showPop_isShow" style="background-color: #f4f4f4; height: 8vw">
          <img class="_mb-w-25" src="@/assets/image/mb-nav-ico.png" alt="" />
        </div> -->
        <img class="_mb-w-60 _mb-mar-right-20" @click.stop="showPop_isShow = !showPop_isShow"
          src="@/assets/image/mb-nav-ico.png" alt="" />
        <div class="_mb-w-60 _border-circle _flex-row-center-mid _mb-mar-right-20"
          style="background-color: #f4f4f4; height: 8vw" @click="is_phone_show = !is_phone_show">
          <img class="_mb-w-30" src="@/assets/image/wechat-ico.png" alt="" />
        </div>

        <div class="_flex-row-mid _mb-pad-x-20 theme-color _mb-font-24 _mb-pad-x-20 _mb-pad-y-10" @click="show_pop"
          style="background: #f4f4f4; border-radius: 100px">
          <img class="_mb-mar-right-10 _mb-w-20" src="@/assets/image/uni-ico.png" alt="" />
          <div>我要寄货</div>
        </div>
      </div>

      <img class="_mb-w-180" :src="web_logo.image_input" alt="" />
    </div>

    <!-- pc占位符 -->
    <div class="_com-head-height _mb-display-none" style="width: 100%" v-if="nav != '/'"></div>
    <!-- 手机占位符 -->
    <div @click.stop class="_mb-com-head-height _pc-display-none" style="width: 100%"></div>

    <div v-if="showPop_isShow" @click="showPop_isShow = false" class="_mb-pos-fixed _w-max _h-max _top _left"
      style="background-color: rgba(0, 0, 0, 0.2); z-index: 12">
      <div class="_pos-rel _h-max _w-max">
        <div class="_h-max _white-bg _pos-abs _left _top transition-06 _over-hid mb-meus-bg _mb-pad-x-30"
          :style="'width:' + showPop_width" @click.stop>
          <div class="_mb-pad-y-35 _mb-pad-x-10 _mb-border-bottom _flex-row" style="justify-content: flex-end">
            <img @click.stop="showPop_isShow = false" class="_mb-w-45" src="@/assets/image/guanbi.png" alt="" />
          </div>

          <router-link class="_mb-w-max _mb-pad-y-25 _border-bottom _flex-row-bet-mid"
            :to="item.data ? item.link + '?data=' + item.data : item.link" v-for="(item, index) in nav_list"
            :key="index" v-show="item.status">
            <div>
              <div>
                <div class="_mb-font-32" :class="nav == item.link ? 'nav_color' : ''">
                  {{ item.title }}
                </div>
                <div class="_mb-font-32" :class="nav == item.link ? 'nav_color' : ''"></div>
              </div>
              <div class="_mb-font-16 _mb-mar-top-15 _grey-99">{{ item.tips }}</div>
            </div>
            <img class="_mb-w-20" src="@/assets/image/right-ico-04.png" alt="" />
          </router-link>

          <div class="_mb-pad-x-20 _mb-mar-top-70 _mb-mar-bottom-115 _flex-row-bet">
            <div class="_flex-col-mid">
              <img class="_mb-w-215 _mb-h-215" :src="routine.image_input" alt="">
              <div class="_mb-font-28 _mb-mar-top-25">官方小程序</div>
            </div>
            <div class="_flex-col-mid">
              <img class="_mb-w-215 _mb-h-215" :src="account.image_input" alt="">
              <div class="_mb-font-28 _mb-mar-top-25">官方公众号</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹窗 -->
    <div class="head-pop" v-if="is_show">
      <div class="_pc-w-300 _mb-w-400 _pos-rel _flex-row-center-mid">
        <img class="_w-max" src="@/assets/image/pop-bg.png" alt="" />
        <div class="_pos-abs-bottom _pc-mar-bottom-30 _mb-pad-bottom-40 _flex-col-center-mid">
          <img class="_w-max _pc-w-210 _mb-w-280" :src="routine.image_input" alt="" />
          <div class="other-btn">其他登录方式</div>
          <div class="login-btn" @click="toLogin">账号密码登录</div>
        </div>

        <img class="_pos-abs _pc-w-40 _btn-hover _mb-w-60" style="bottom: -20%; cursor: pointer" @click="open_show"
          src="@/assets/image/pop-push.png" alt="" />
      </div>
    </div>

    <div class="head-pop" v-if="is_phone_show">
      <div class="_pc-w-300 _mb-w-400 _pos-rel _flex-row-center-mid">
        <!-- <img class="_w-max" src="@/assets/image/pop-bg.png" alt="" /> -->
        <div class="_pc-mar-bottom-30 _mb-pad-bottom-40 _flex-col-center-mid">\
          <img class="_w-max" :src="account.image_input[0]" alt="" />
          <!-- <div class="other-btn">其他登录方式</div>
          <div class="login-btn" @click="toLogin">账号密码登录</div> -->
        </div>

        <img class="_pos-abs _pc-w-40 _btn-hover _mb-w-60" style="bottom: -20%; cursor: pointer"
          @click="open_phone_show" src="@/assets/image/pop-push.png" alt="" />
      </div>
    </div>

    <!-- head图片 -->
    <div class="_pos-rel _flex-row-center-mid _mb-display-none" style="height: auto" v-if="nav == '/'">
      <div class="swiper-container" style="margin: 0; width: 100%">
        <div class="swiper-wrapper">
          <!-- pc轮播 -->
          <div class="swiper-slide" v-for="item in index_nav" :key="item.id">
            <img style="width: 100%; vertical-align: bottom" :src="item.image" alt="" />
            <router-link :to="item.link" class="_w-max _h-max _pos-abs" style="z-index: 8; left: 0"
              v-if="item.is_new == 0"></router-link>
            <a :href="item.link" class="_w-max _h-max _pos-abs" style="z-index: 8; left: 0" v-else></a>
          </div>
        </div>
        <div v-if="index_nav.length > 1"
          class="swiper-button-prev _pc-w-40 _pc-h-55 _pc-mar-x-80 _swip-pre _mb-w-30 _mb-mar-x-30" style="left: 0%">
          <img class="_w-max" src="@/assets/image/swip-pre.png" alt="" />
        </div>
        <div v-if="index_nav.length > 1"
          class="swiper-button-next _pc-w-40 _pc-h-55 _pc-mar-x-80 _swip-next _mb-w-30 _mb-mar-x-30" style="right: 0%">
          <img class="_w-max" src="@/assets/image/swip-next.png" alt="" />
        </div>
      </div>

      <a href="#/" class="_pos-abs _z-index-1 _mb-display-none" style="bottom: 10%"><img class="_pc-w-70"
          src="@/assets/image/head-down.png" alt="" /></a>
    </div>

    <div class="_pos-rel _flex-row-center-mid _pc-display-none" style="height: auto" v-if="nav == '/'">
      <div class="swiper-container" style="margin: 0; width: 100%">
        <div class="swiper-wrapper">
          <!-- 手机轮播 -->
          <div class="swiper-slide" v-for="(item, index) in index_nav" :key="index">
            <img style="width: 100%; vertical-align: bottom" :src="item.mb_image" alt="" />
            <router-link :to="item.link" class="_w-max _h-max _pos-abs" style="z-index: 8; left: 0"
              v-if="item.is_new == 0"></router-link>
            <a :href="item.link" class="_w-max _h-max _pos-abs" style="z-index: 8; left: 0" v-else></a>
          </div>
        </div>
        <div v-if="index_nav.length > 1"
          class="swiper-button-prev _pc-w-40 _pc-h-55 _pc-mar-x-80 _swip-pre _mb-w-30 _mb-mar-x-30" style="left: 0%">
          <img class="_w-max" src="@/assets/image/swip-pre.png" alt="" />
        </div>
        <div v-if="index_nav.length > 1"
          class="swiper-button-next _pc-w-40 _pc-h-55 _pc-mar-x-80 _swip-next _mb-w-30 _mb-mar-x-30" style="right: 0%">
          <img class="_w-max" src="@/assets/image/swip-next.png" alt="" />
        </div>
      </div>

      <a href="#/" class="_pos-abs _z-index-1 _mb-display-none" style="bottom: 10%"><img class="_pc-w-70"
          src="@/assets/image/head-down.png" alt="" /></a>
    </div>

    <!-- pc图 -->
    <div class="_mb-display-none" v-for="item in nav_list" :key="item.id"
      v-show="nav != '/' && isQuestion && nav == item.link">
      <img style="width: 100%; vertical-align: bottom" :src="item.image" alt="" />
    </div>
    <!-- 手机图 -->
    <div class="_pc-display-none" v-for="(item, index) in nav_list" :key="index"
      v-show="nav != '/' && isQuestion && nav == item.link">
      <img style="width: 100%; vertical-align: bottom" :src="item.mb_image" alt="" />
    </div>
  </div>
</template>

<script>
import Swiper from "@/assets/js/swiper.min.js";
export default {
  name: "PcWebHead",

  data() {
    return {
      nav: this.$root._route.path,
      is_show: false,
      is_phone_show: false,
      nav_list: [],
      scroll_top: null,
      nav_img: null,
      isQuestion: false,
      routine: {},
      account: {},
      web_logo: {}, //网站logo
      index_logo: {}, //主页logo
      index_nav: [], //主页轮播图

      showPop_isShow: false,
      showPop_width: "0px",
    };
  },

  beforeMount() {
    this.get_nav();
  },
  created() {
    let phoneHead = document.getElementById("phoneHead");
    let realHead = document.getElementsByClassName("_mb-com-head-height");
    realHead[0].style.height = phoneHead.offsetHeight + "px";
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.watch_head();
    this.get_routine();
    this.get_logo();
  },

  watch: {
    $route: {
      handler(newval, oldval) {
        // window.dataLayer = window.dataLayer || []
        // function gtag() {
        //   window.dataLayer.push(arguments)
        // }
        // gtag('js', new Date());
        // gtag('config', 'G-8Y015BYEEY');
        if (newval == oldval) {
          return;
        }
        //fullPath,当前的路由路径
        this.nav = newval.path;
        if (this.nav == "/") {
          this.nav_swiper();
        }
        let nav = /question/;
        if (nav.test(this.$root._route.path)) {
          this.isQuestion = false;
        } else {
          this.isQuestion = true;
        }

        this.showPop_isShow = false;
      },
    },

    nav_img: {
      handler(newval, oldval) {
        this.nav_swiper();
      },
    },

    showPop_isShow: {
      handler() {
        if (this.showPop_isShow) {
          //页面不能滚动
          document.body.style.height = "100vh";
          document.body.style.overflow = "hidden";
          setTimeout(() => {
            this.showPop_width = "80vw";
          }, 5);
        } else {
          //页面开启滚动
          document.body.style.height = "unset";
          document.body.style.overflow = ""; //出现滚动条
          this.showPop_width = "0px";
        }
      },
    },
  },

  methods: {
    toLogin() {
      window.location.assign("https://yipu.uxphp.net/h5");
    },

    watch_head() {
      let nav = /question/;
      if (nav.test(this.$root._route.path)) {
        this.isQuestion = false;
      } else {
        this.isQuestion = true;
      }
    },

    handleScroll() {
      this.scroll_top = document.documentElement.scrollTop;
    },

    show_pop() {
      this.is_show = true;
      let body = document.querySelector("body");
      body.style.height = 100 + "vh";
      body.style.overflow = "hidden";
    },

    open_show() {
      this.is_show = false;
      let body = document.querySelector("body");
      body.style.height = 100 + "%";
      body.style.overflow = "auto";
    },

    open_phone_show() {
      this.is_phone_show = false;
      let body = document.querySelector("body");
      body.style.height = 100 + "%";
      body.style.overflow = "auto";
    },

    nav_swiper() {
      let that = this;
      setTimeout(() => {
        var mySwiper = new Swiper(".swiper-container", {
          direction: "horizontal",
          loop: that.index_nav.length > 1 ? true : false,
          speed: 1500,
          autoplay: {
            delay: 3500,
            disableOnInteraction: false,
          },

          // 如果需要分页器
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },

          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      }, 200);
    },

    async get_routine() {
      //获取小程序/公众号
      let that = this;
      await that.$axios
        .get("site/get_site_newList?cid=7&page=1&limit=15")
        .then((res) => {
          console.log(res, '*****');
          that.routine = res.data.data.all[2];
          that.account = res.data.data.all[0];
          console.log(that.account.image_input[0], '//////******//////////');
        });
    },

    async get_logo() {
      //获取网站logo
      let that = this;
      await that.$axios
        .get("site/get_site_newList?cid=25&page=1&limit=15")
        .then((res) => {
          that.web_logo = res.data.data.all[0];
          that.index_logo = res.data.data.all[1];
        });
    },

    async get_nav() {
      //获取导航栏
      await this.$axios.get("site/nav_list/0", {}).then((res) => {
        this.nav_list = res.data.data.list;
        let that = this;
        this.nav_list.forEach((val) => {
          if (val.title == '网站首页') {
            val.tips = 'Home Page'
          } else if (val.title == '关于我们') {
            val.tips = 'About Us'
          } else if (val.title == '集运服务') {
            val.tips = 'Our Service'
          } else if (val.title == '路线介绍') {
            val.tips = 'Route Introduction'
          } else if (val.title == '物流查询') {
            val.tips = 'Tracking Enquiry'
          } else if (val.title == '行业动态') {
            val.tips = 'Industry News'
          }
          if (val.link == "/") {
            this.nav_img = val.image;
            this.nav_swiper();
            this.index_nav = val.sub_nav;
          }
        });
      });
    },
  },
};
</script>

<style>
a {
  text-decoration: unset;
  color: unset;
}

a:hover {
  color: unset;
}

a:visited {
  color: unset;
}

a:active {
  color: unset;
}

input {
  border: 0;
}

body {
  margin: 0;
}

.nav_color {
  color: var(--theme-color);
}

.mb-meus-bg {
  background-image: url("../assets/image/meus-bg.png");
}

._com-head-box {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 11;
}

._com-head-height {
  height: 8vh;
}

.head-pop {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
}

.nav-index-border-top {
  border-top: 2px solid #fff;
}

.nav-border-top {
  border-top: 2px solid var(--theme-color);
}

.nav-opac {
  opacity: 0.6;
}

._wechat-box ._pos-abs {
  display: none;
}

._wechat-box:hover ._pos-abs {
  display: block;
}

._swip-next {
  background-image: url("") !important;
}

._swip-pre {
  background-image: url("") !important;
}

._btn-hover:hover {
  opacity: 1;
}

.transition-06 {
  transition: 0.6s;
}

@media screen and (max-width: 768px) {
  ._com-head-height {
    height: 3vh;
  }

  ._mb-pad-y-010 {
    padding-top: 0.23052vh;
    padding-bottom: 0.23052vh;
  }

  ._mb-com-head-height {
    height: 8.37139vh;
  }

  ._mb-grid-nav-list {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .other-btn {
    width: 100%;
    font-size: 3.20vw !important;
    color: #999999;
    display: flex;
    align-items: center;
    margin-top: 5.47vw !important;
  }

  .other-btn::before {
    content: "";
    display: inline-block;
    flex: 1;
    height: 1px;
    background: #d8d8d8;
    margin-right: 3.60vw !important;
  }

  .other-btn::after {
    content: "";
    display: inline-block;
    flex: 1;
    height: 1px;
    background: #d8d8d8;
    margin-left: 3.60vw !important;
  }

  .login-btn {
    width: 44.47vw !important;
    height: 11.47vw !important;
    background: rgba(217, 38, 33, 0.15);
    border-radius: 0.93vw !important;
    font-size: 3.73vw !important;
    color: #d92621;
    line-height: 11.47vw !important;
    text-align: center;
    margin-top: 5.07vw !important;
    cursor: pointer;
    transition: all 0.5s;
  }

  .login-btn:hover {
    background: rgba(217, 38, 33, 1);
    color: #fff;
  }
}

.other-btn {
  width: 100%;
  font-size: 0.83vw;
  color: #999999;
  display: flex;
  align-items: center;
  margin-top: 1.35vw;
}

.other-btn::before {
  content: "";
  display: inline-block;
  flex: 1;
  height: 1px;
  background: #d8d8d8;
  margin-right: 0.73vw;
}

.other-btn::after {
  content: "";
  display: inline-block;
  flex: 1;
  height: 1px;
  background: #d8d8d8;
  margin-left: 0.73vw;
}

.login-btn {
  width: 13.13vw;
  height: 2.81vw;
  background: rgba(217, 38, 33, 0.15);
  border-radius: 0.36vw;
  font-size: 0.83vw;
  color: #d92621;
  line-height: 2.81vw;
  text-align: center;
  margin-top: 1.2vw;
  cursor: pointer;
  transition: all 0.5s;
}

.login-btn:hover {
  background: rgba(217, 38, 33, 1);
  color: #fff;
}
</style>
