import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MetaInfo from 'vue-meta-info'
import axios from 'axios'

import '@/assets/css/app.css'
import '@/assets/css/mbapp.css'
import '@/assets/css/animate.css'
import '@/assets/css/swiper.css'

import Viewer from 'v-viewer' // 引入js
import 'viewerjs/dist/viewer.css' // 引入css
Vue.use(Viewer);
import wow from '@/assets/js/wow.min.js';
Vue.prototype.$wow = wow;

// Google Analytics代码
// const script = document.createElement('script')
// script.async = true
// script.src = `https://www.googletagmanager.com/gtag/js?id=G-8Y015BYEEY`
// document.head.appendChild(script)

// script.onload = () => {
//   window.dataLayer = window.dataLayer || []
//   function gtag() {
//     window.dataLayer.push(arguments)
//   }
//   console.log('进入script.onload');
//   // console.log();
//   gtag('js', new Date());
//   gtag('config', 'G-8Y015BYEEY');
// }




//用于设置title、描述、关键字，解决SEO的问题
Vue.use(MetaInfo);

// Vue.use(axios);

// axios.defaults.baseURL = 'http://www.2312yipu.com/api/jiyun';
axios.defaults.baseURL = 'https://yipu.uxphp.net/api/jiyun';

Vue.prototype.$axios = axios;
Vue.config.productionTip = false

new Vue({
  router,

  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  }
}).$mount('#app')

